import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Link from "next/link";
import React from "react";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import InfoIcon from "@mui/icons-material/Info";

export default function MainDrawer({ drawerState, setDrawerState }) {
  return (
    <Drawer open={drawerState} onClose={() => setDrawerState(false)}>
      <Box
        sx={{
          width: 250,
        }}
        role="presentation"
        onClick={() => setDrawerState(false)}
        onKeyDown={() => setDrawerState(false)}
      >
        <List>
          <Link href={`/azbuka`} passHref>
            <ListItem button key={"azbuka"}>
              <ListItemIcon>
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText primary={"Азбука фиалковода"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link href={`/help`} passHref>
            <ListItem button key={"help"}>
              <ListItemIcon>
                <HelpCenterIcon />
              </ListItemIcon>
              <ListItemText primary={"Помощь"} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          <Link href={`/about`} passHref>
            <ListItem button key={"about"}>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"О приложении"} />
            </ListItem>
          </Link>
        </List>
      </Box>
    </Drawer>
  );
}
